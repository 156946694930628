import React, { useContext } from 'react'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'

type Props = {}

const UserProfile = (props: Props) => {
    const {user,t,setshowGameRules} = useContext(appContext)
    const text = t('taskPage')
  return (
    <div className="game-avatar">
          <div className="game-avatar__status">
            <img src={user?.avatar} className="game-avatar__img" />
            <div className="game-avatar__contain">
              <p className="game-avatar__title">{user?.first_name}</p>
              <div className="game-avatar__block">
                <img src="./img/star-cup.svg" />
                <p className="game-avatar__text" onClick={() => setshowGameRules(true)}>{user?.level} {text.level}</p>
              </div>
            </div>
          </div>
          <div className="main-avatar__block" style={{marginLeft:'auto'}}>
            <img src="./img/coin.svg" />
            <p className="main-avatar__text">{formatNumber(user?.balance)}</p>
          </div>
        </div>
  )
}

export default UserProfile