import { RouterProvider, useLocation, useNavigate, useRoutes } from "react-router-dom";
import useAppData from "./hooks/use-app-data";
import routes from "./routes";
import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import tg from "utils/tg";
import Navigation from "UI/Navigation";
import GameRules from "UI/GameRules";
import { Bounce, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function App() {
  const navigation = useNavigate()
  const {AppContext, AppContextValue} = useAppData();
  const element = useRoutes(routes.map(({route}) => route));
  const location = useLocation();

  const reloadBackButton = () => {
    if (tg.BackButton.isVisible) return false
    tg.BackButton.onClick(() => {
      navigation(-1)
    })
    tg.BackButton.show()
  }

  useEffect(() => {
    tg.expand()
    tg.disableVerticalSwipes()
    // reloadBackButton()
    // tg.onEvent('viewportChanged',reloadBackButton)

    // tg.CloudStorage.removeItem('entry')
    // tg.CloudStorage.getItem('entry',(err,val) => {
    //   console.log("🚀 ~ tg.CloudStorage.getItem ~ val:", !val)
    //   if (!val) navigation( '/entry');
    // })
  }, [])
  if (!element) return null;
  const {showGameRules,setshowGameRules} = AppContextValue
  return (
    <AppContext.Provider value={AppContextValue}>
       <AnimatePresence mode="sync">
       {React.cloneElement(element, { key: location.pathname })}
       </AnimatePresence>
       <Navigation/>
    {showGameRules && <GameRules close={() => setshowGameRules(false)}/>}
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      draggableDirection="y"
      pauseOnHover
      theme="dark"
      transition={Bounce}
      />
       </AppContext.Provider>
  );
}

export default App;
