import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GameEnergy from 'UI/GameEnergy'
import PageWrapper from 'UI/PageWrapper'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'
import tg from 'utils/tg'
import LangSelect from './components/LangSelect'
import Icon from 'UI/Icon'
import Farming from './components/Farming'
import PendingButton from 'UI/PendingButton'
import { toast } from 'react-toastify'

type Props = {}

const Home = (props: Props) => {
  const {user,updateUserInfo,t,setshowGameRules} = useContext(appContext)
  const text = t('homePage')
  const navigate = useNavigate()
  useEffect(() => {
    updateUserInfo()
  }, [])
  return (
    <PageWrapper>
      <div className="main-block">
      <LangSelect/>
      <img src="./img/logo.svg" className="main__logo" />
    </div>
    <div className="main-form">
      <div className="main-avatar">
        <img src={user?.avatar} className='avatar-photo'/>
        <p className="main-avatar__name">{user?.first_name}</p>
        <div className="main-avatar__contain">
          <div className="main-avatar__block">
            <img src="./img/star-cup.svg" />
            <p onClick={() => setshowGameRules(true)} className="main-avatar__text">{user?.level} {text.level}</p>
          </div>
          <div className="main-avatar__block">
            <img src="./img/coin.svg" />
            <p className="main-avatar__text">{formatNumber(user?.balance)}</p>
          </div>
          <div className="main-avatar__block">
            <div style={{position:'relative'}}>
              <Icon icon='calendar'/>
              <p className='everyday-counter'>{user?.days_in_game}</p>
            </div>
            <p className="main-avatar__text">{text.gameDays}</p>
          </div>
        </div>
      </div>
      <GameEnergy style={{padding:0}} showEnergy/>
      <Farming/>
      
      {!user?.energy_seconds
      ? <button className="main-statistics__button" onClick={(e) =>  navigate('/game')}>{text.startGame}</button>
      : <PendingButton style={{marginTop:20}} text={text.energyRecovery} timeStart={user?.energy_seconds || 500} callback={updateUserInfo}/> 
      }
    </div>
    </PageWrapper>
  )
}

export default Home