import { motion } from 'framer-motion'
import React, { useContext, useState } from 'react'
import appContext from 'utils/app-context'

type Props = {}

const LangSelect = (props: Props) => {
  const {user,lang,setLanguage} = useContext(appContext)
    const [opened, setopened] = useState(false)
  return (
    <div className="main__img-group" onClick={() =>  setopened(!opened)}>
            <img src="./img/eng.svg" />
            <img style={{width:24,height:18}} src={`./img/${lang == 'ru' ? 'rus' : 'usa'}.svg`} alt="" />
        <motion.ul 
        initial={{
            x:-100,
            opacity:0
        }}
        animate={{
            x:opened ? 0 : -100,
            opacity:Number(opened)
        }}
        className="lang-select">
          <li onClick={() => setLanguage('en')}>
            <img src="./img/usa.svg" alt="" />
            <p>English</p>
          </li>
          <li onClick={() => setLanguage('ru')}>
            <img src="./img/rus.svg" alt="" />
            <p>Русский</p>
          </li>
        </motion.ul>
      </div>
  )
}

export default LangSelect