import React, { useContext } from 'react'
import appContext from 'utils/app-context'
import formatNumber from 'utils/format-number'

type Props = {
  close:() => void
}

const levels = [
  {
    level:1,
    perTap:1,
    cost:0
  },
  {
    level:2,
    perTap:3,
    cost:30000
  },
  {
    level:3,
    perTap:3,
    cost:50000
  },
  {
    level:4,
    perTap:5,
    cost:200000
  },
  {
    level:5,
    perTap:7,
    cost:350000
  },
  {
    level:6,
    perTap:10,
    cost:1000000
  },
]

const GameRules = ({close}: Props) => {
  const {t} = useContext(appContext)
  const text = t('gameTable')
  return (
    <div className="popup" id="popup">
      <div className="popup__content">
        <span className="popup__close" onClick={close}> <div>×</div> </span>
        <table className='levels-table'>
          <thead>
            <tr>
              <td>{text.level}</td>
              <td>{text.cost}</td>
              <td>{text.coins}</td>
            </tr>
          </thead>
          <tbody>
            {levels.map(l => (
              <tr>
                <td>
                  {l.level}
                </td>
                <td>
                  {formatNumber(l.cost)}
                </td>
                <td>
                <div>
                <img src="./img/coin.svg"/>
                + {l.perTap}
                </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className='popup__text'>{text.desc}
        </p>
      </div>
    </div>
  )
}

export default GameRules