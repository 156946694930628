import React from 'react';

type Props = {
  color: string;
  numElectrons: number;
};

const GameParticle = ({ color, numElectrons }: Props) => {
  // Create an array to hold electron positions
  const electronPositions = Array.from({ length: numElectrons }, (_, i) => {
    const angle = (i / numElectrons) * 2 * Math.PI; // Calculate angle
    const radius = 27; // Radius of the orbit (half the width/height of the atom)
    const x = 27 + radius * Math.cos(angle); // X coordinate adjusted for center
    const y = 27 + radius * Math.sin(angle); // Y coordinate adjusted for center
    return { x, y };
  });

  return (
    <div className='game-particle-atom' style={{ position: 'relative' }}>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4595 7.49994C15.4595 11.6421 12.1016 14.9999 7.95947 14.9999C3.81734 14.9999 0.459473 11.6421 0.459473 7.49994C0.459473 3.3578 3.81734 -6.10352e-05 7.95947 -6.10352e-05C12.1016 -6.10352e-05 15.4595 3.3578 15.4595 7.49994Z" fill={color} />
        <path d="M7.57786 9.3412V5.54053H8.22282V9.3412H7.57786ZM6 7.76335V7.11838H9.80068V7.76335H6Z" fill="white" />
      </svg>

      {electronPositions.map((pos, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            left: pos.x,
            top: pos.y,
            transform: 'translate(-50%, -50%)', // Center the electron on its position
          }}
        >
          <Electron />
        </div>
      ))}
    </div>
  );
};

const Electron = () => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8108 6.67556C12.8108 10.0341 10.0883 12.7566 6.72976 12.7566C3.37127 12.7566 0.648682 10.0341 0.648682 6.67556C0.648682 3.31708 3.37127 0.594482 6.72976 0.594482C10.0883 0.594482 12.8108 3.31708 12.8108 6.67556Z" fill="white" />
    <path d="M8.22853 6.59448V7.22793H5.64868V6.59448H8.22853Z" fill="black" />
  </svg>
);

export default GameParticle;
