import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;
interface Responce {
  success: boolean;
  energy: number;
}
export default async ():Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "game_start";
    API_URL.searchParams.set("tg_id", String(tg_id));
    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
