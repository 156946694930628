export default (price?: string | number): string => {
  // Преобразуем входное значение в число и округляем до двух знаков после запятой
  const priceNum = Number(price || 0);

  // Округляем до двух знаков после запятой, избегая проблем с точностью
  const roundedPrice = Math.round(priceNum * 100) / 100;

  // Преобразуем округленное число в строку
  const priceStr = roundedPrice.toString();

  // Разбиваем на целую и дробную части
  const [integerPart, decimalPart] = priceStr.split('.');

  // Используем регулярное выражение для вставки пробелов в целую часть
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Если есть дробная часть, добавляем ее, если нет — возвращаем только целую часть
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};
