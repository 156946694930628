import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;
interface Responce {
  result: boolean;
  alert: string | null;
}
export default async (id:string | number):Promise<Responce | null> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "product_buy";
    API_URL.searchParams.set("tg_id", String(tg_id));
    API_URL.searchParams.set("product_id", String(id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    console.log("🚀 ~ error:", error)
    return null
  }
};
